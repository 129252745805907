import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "flagsmith/react";

import Home from "./views/Home";
import Loading from "./components/Loading";
import Steps from "./components/Steps";
import Questions from "./components/Questions";
import Agreements from "./components/Agreements";
import Congratulations from "./components/Congratulations";
import Contact from "./components/Contact";
import Choices from "./components/Choices";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const flags = useFlags(['freemium_access']);
  const freemiumAccess = flags.freemium_access.enabled || false;

  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <div id="app" className="d-flex flex-column h-100">
        <Container className="flex-grow-1 mt-5">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/steps" element={<Steps />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/agreements" element={<Agreements />} />
            <Route path="/congratulations" element={<Congratulations />} />
            <Route path="/contact" element={<Contact />} />
            {freemiumAccess && <Route path="/choices" element={<Choices />} />}
          </Routes>
        </Container>
      </div>
    </Router>
  );
};

export default App;