import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFlags } from 'flagsmith/react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { TextMask } from "./MaskInput";

import { isBirthdateValid, isPhoneValid, formatDate, formatPhoneNumber } from "../utils/validators";
import { useAuthContext } from "../utils/AuthContext";
import { continuePostLogin } from "../utils/auth";
import { updateUserMeta, activatePrescription, createSubscriptionStatus } from "../utils/services";
import { SUBSCRIPTION_STATUS } from "../utils/consts";

const Questions = () => {
  const flags = useFlags(['freemium_access']);
  const freemiumAccess = flags.freemium_access.enabled || false;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phone, setPhone] = useState("");

  const [birthdateError, setBirthdateError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [recordError, setRecordError] = useState(false);

  const questionsAnswered = firstName && lastName && birthdate && phone;

  const handleValidate = () => {
    if (birthdate) {
      if (isBirthdateValid(birthdate)) {
        setBirthdateError(false);
      } else {
        setBirthdateError(true);
      }
    } else {
      setBirthdateError(false);
    }

    if (phone) {
      if (isPhoneValid(phone)) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
    } else {
      setPhoneError(false);
    }
  }

  const {sessionState, userId, inMobile} = useAuthContext();

  const navigate = useNavigate();

  const handleNextStep = async () => {
    try {
      const prescriptionData = {
        "userId": userId,
        "firstName": firstName.trim().toLowerCase(),
        "lastName": lastName.trim().toLowerCase(),
        "birthdate": isBirthdateValid(birthdate) ? birthdate.format("MM/DD/YYYY") : "",
        "phone": isPhoneValid(phone) ? formatPhoneNumber(phone) : ""
      };

      await activatePrescription(prescriptionData);

      const userData = {
        userId: userId,
        subscriptionStatus: SUBSCRIPTION_STATUS.PRESCRIPTION_ACTIVE,
        date: formatDate(new Date())
      };

      await createSubscriptionStatus(userData);

      if (!inMobile) {
        continuePostLogin(sessionState, userId, {subscriptionStatus: SUBSCRIPTION_STATUS.PRESCRIPTION_ACTIVE});
      } else {
        navigate("/congratulations");
      }
    } catch (error) {
      console.log(error);
      setRecordError(true);
    }
  }

  const handlePrevStep = async () => {
    try {
      const userMeta = {
        "user_id": userId,
        "user_metadata": {
          "chosenPrescription": false
        }
      };

      await updateUserMeta(userMeta);

      navigate("/choices");
    } catch (error) {
      console.log(error);
    }
  }

  const showFreeTrialLink = !inMobile && freemiumAccess;

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="heading" sx={{
        mx: 2,
        mb: 1,
      }}>
        Activate prescription
      </Typography>

      <Typography variant="body" sx={{
        mx: 2,
        my: 1,
      }}>
        Fill out the fields below and&nbsp;

        <Typography variant="bodyBold">
          with answers that will match the information you provided your prescriber.
        </Typography>
      </Typography>

      {recordError && 
        <Alert 
          icon={false} 
          variant="filled" 
          color="customBrown" 
          sx={{m: 2}}
        >
          Sorry, something you entered does not match our records. Please make sure all details match those you gave to your prescriber.
        </Alert>
      }

      <TextField
        sx={{m: 2}}
        label="First name"
        value={firstName}
        onChange={(event) => {
          setFirstName(event.target.value);
          setRecordError(false);
        }}
      />

      <TextField
        sx={{m: 2}}
        label="Last name"
        value={lastName}
        onChange={(event) => {
          setLastName(event.target.value);
          setRecordError(false);
        }}
      />

      <DesktopDatePicker
        inputFormat="MM/DD/YYYY"
        label="Date of birth"
        value={birthdate}
        onChange={(value) => {
          setBirthdate(value);
          setRecordError(false);
        }}
        renderInput={(params) => 
          <TextField 
            sx={{m: 2}}
            {...params}
            error={birthdateError}
            helperText={birthdateError ? "Please enter a valid date of birth." : null}
            onBlur={handleValidate}
        />}
      />

      <TextField
        sx={{m: 2}}
        label="Phone number"
        value={phone}
        onChange={(event) => {
          setPhone(event.target.value);
          setRecordError(false);
        }}
        error={phoneError}
        helperText={phoneError ? "Please enter a valid phone number." : null}
        onBlur={handleValidate}
        InputProps={{
          inputComponent: TextMask,
        }}
      />

      <Button
        size="large"
        variant="contained"
        color="customRed"
        sx={{
          borderRadius: '25px',
          m: 2,
        }} 
        disabled={!questionsAnswered || birthdateError || phoneError}
        onClick={handleNextStep}
      >
        Activate
      </Button>

      <Box sx={{
        mx: 2,
        display: 'flex',
        justifyContent: showFreeTrialLink ? 'space-between' : 'space-around'
      }}>
        <Link color="#6C6DB9" component="button" onClick={() => navigate("/contact")}>Contact us</Link>
        {showFreeTrialLink && <Link color="#6C6DB9" component="button" onClick={handlePrevStep}>Try the free trial</Link>}
      </Box>
    </Box>
  )
}

export default Questions;