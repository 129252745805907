import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';

import { formatDate } from "../utils/validators";
import { useAuthContext } from "../utils/AuthContext";
import { continuePostLogin } from "../utils/auth";
import { updateUserMeta, deleteUserProgram, createSubscriptionStatus } from "../utils/services";
import { SUBSCRIPTION_STATUS } from "../utils/consts";
//import { logFacebookEvent } from "../utils/facebook";

const Choices = () => {
  const [choice, setchoice] = useState("");

  const { sessionState, userId } = useAuthContext();

  const navigate = useNavigate();

  const handleNextStep = async () => {
    if (choice === "prescription") {
      try {
        const userMeta = {
          "user_id": userId,
          "user_metadata": {
            "chosenPrescription": true
          }
        };

        await updateUserMeta(userMeta);
        navigate("/questions");
      } catch (error) {
        console.log(error);
      }
    } else if (choice === "free trial") {
      try {
        const userData = {
          userId: userId,
          subscriptionStatus: SUBSCRIPTION_STATUS.FREE_TRIAL_STARTED,
          date: formatDate(new Date())
        };

        await createSubscriptionStatus(userData);

        await deleteUserProgram(userId);

        //await logFacebookEvent("Activate your Prescription");

        continuePostLogin(sessionState, userId, { subscriptionStatus: SUBSCRIPTION_STATUS.FREE_TRIAL_STARTED });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="heading" sx={{
        mx: 2,
        mb: 1,
      }}>
        Get started with Stanza
      </Typography>

      <Typography variant="body" sx={{
        mx: 2,
        my: 1,
      }}>
        If you already have a prescription, activate it now. Or get started immediately with a free trial.
      </Typography>

      <List>
        <Divider />

        <ListItemButton sx={{ alignItems: 'flex-start' }} onClick={() => setchoice("prescription")}>
          <ListItemText
            primary={
              <Typography variant="bodyLarge" sx={{
                display: 'block',
                my: 1,
              }}>
                Activate your prescription
              </Typography>
            }
            secondary={
              <Typography variant="body" sx={{
                display: 'block',
                my: 1,
              }}>
                Answer a few questions to activate your prescription.
              </Typography>
            }
          />
          <Radio
            color="customGreen"
            checked={choice === "prescription"}
            onChange={(event) => {
              setchoice(event.target.value);
            }}
            value="prescription"
          />
        </ListItemButton>

        <Divider />

        <ListItemButton sx={{ alignItems: 'flex-start' }} onClick={() => setchoice("free trial")}>
          <ListItemText
            primary={
              <Typography variant="bodyLarge" sx={{
                display: 'block',
                my: 1,
              }}>
                Start your free trial
              </Typography>
            }
            secondary={
              <Typography variant="body" sx={{
                display: 'block',
                my: 1,
              }}>
                Explore Stanza to see if it’s right for you and learn how to unlock full access.
              </Typography>
            }
          />
          <Radio
            color="customGreen"
            checked={choice === "free trial"}
            onChange={(event) => {
              setchoice(event.target.value);
            }}
            value="free trial"
          />
        </ListItemButton>
        <Divider />
      </List>

      <Button
        size="large"
        variant="contained"
        color="customRed"
        sx={{
          borderRadius: '25px',
          m: 2,
        }}
        disabled={!choice}
        onClick={handleNextStep}
      >
        Next
      </Button>
    </Box>
  )
}

export default Choices;